import { graphql } from 'gatsby'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import { SEO } from '../components/seo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import RelatedPost from '../components/RelatedPost'

export default function singlePost({ data, pageContext }) {
   
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark

    const baseUrl = "https://www.googolweb.com";

  return (
    <SEO title={frontmatter.title} description="">
    <Layout>
        <section className='abstract text-dark w-100' style={{ marginTop: "74px" }}>
            <div className='container' style={{ maxWidth: "800px" }}>
                <div className='row row-padding' style={{ padding: "100px 0" }}>

                    <GatsbyImage image={getImage(frontmatter.featuredImage)} className="img-fluid" alt={frontmatter.title} />
                    <h1 className='mt-5 mb-2 text-capitalize'>{ frontmatter.title }</h1>
                    <p className="mb-3 text-muted"><small>{frontmatter.date}</small></p>

                    <div
                        className="blog-post-content"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />

                    <h5 className="my-3">Share this post</h5>

                    <ul className="list-unstyled lead d-flex">
                        <li>
                            <a className="pe-2 me-2 icons bg-primary" href={"https://www.facebook.com/sharer/share.php?u=" + baseUrl + frontmatter.slug} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebookF} className='text-white' role='button' />
                            </a> 
                        </li>
                        <li>
                            <a className="px-2 me-2 icons bg-primary" href={`https://twitter.com/share?url=${baseUrl}${frontmatter.slug} &text ${frontmatter.title} &viatwitterHandle`} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} className='text-white' role='button' />
                            </a>
                        </li>
                        <li>
                            <a className="px-2 me-2 icons bg-primary" href={"https://www.linkedin.com?shareArticle=" + baseUrl + frontmatter.slug} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedinIn} className='text-white' role='button' />
                            </a>
                        </li>
                    </ul>

                    <hr className='my-5' />

                    <h5 className='mb-4'>Recent posts...</h5>
                    
                    <RelatedPost/>
                    
                </div>
            </div>
        </section>
    </Layout>
    </SEO>
  )
}


export const postQuery = graphql`
    query SinglePostQuery($id: String!) {
        markdownRemark(id: {eq: $id}) {
            frontmatter {
                date(formatString: "MMM DD, YYYY")
                slug
                title
                featuredImage {
                    childImageSharp {
                        gatsbyImageData(width: 800, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
            html
        }
    }
`