import { StaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function RelatedPost() {

    return(
        <StaticQuery
            query={RelatedPostQuery}
            render={(data) => (
                <>
                    {
                        data.allMarkdownRemark.edges.map(({node}) => (
                            <div className="col-md-4" key={node.id}>
                                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative" style={{ height: "281px" }}>
                                    <div className="col d-flex flex-column position-static">
                                        <div className="col-auto d-lg-block">
                                        <GatsbyImage image={getImage(node.frontmatter.featuredImage)} alt={node.frontmatter.title} style={{ width: "100%", height: "150px", objectFit: "cover" }} />
                                        </div>
                                        <div className='p-4'>
                                        <h6 className="mb-0">{node.frontmatter.title}</h6>
                                        <Link to={node.frontmatter.slug} className="stretched-link">
                                            <small>Continue reading</small>
                                        </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </>
            )}
        />
    )
    
}

const RelatedPostQuery = graphql`
    query RelatedPostQuery {
        allMarkdownRemark(limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
            edges {
              node {
                frontmatter {
                  title
                  slug
                  date(formatString: "MMM DD YYYY")
                  excerpt
                  featuredImage {
                    childImageSharp {
                        gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
                    }
                  }
                }
                id
              }
            }
        }
    }
  
`